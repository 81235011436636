import { useState } from "react";
import { useTranslation } from "next-i18next";
import { Content } from "@prismicio/client";
import { Link, Text } from "@components/ui";
import cn from "clsx";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import { PrismicNextImage } from "@prismicio/next";
import { URL_PAGE_OPTIMUM } from "@middleware/constants";
import { ModalVideo } from "@components/commun/ModalVideo/ModalVideo";
import style from "./HomeAccelerer.module.scss";

export type HomeAccelererProps =
  SliceComponentProps<Content.HomeAccelererSlice>;

const HomeAccelerer = ({ slice }: HomeAccelererProps): JSX.Element => {
  const { t } = useTranslation();
  const [showVideo, setShowVideo] = useState(false);

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={style.root}
    >
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className={style.title}>
            <PrismicRichText field={slice.primary.title} />
          </div>
          <div
            className={cn(
              "lk-flex space-between lk-flex-mob-colomn",
              style.content,
            )}
          >
            <div className={style.video}>
              <div className={style.logo}>
                <PrismicNextImage width={250} field={slice.primary.logo} />
              </div>
              <div
                role="presentation"
                className={style.feature}
                onClick={() => {
                  setShowVideo(true);
                }}
              >
                <PrismicNextImage
                  field={slice.primary.featureVideo}
                  title={slice.primary.featureVideo.alt ?? ""}
                />
              </div>
              <div className={style.action}>
                <Link
                  href={URL_PAGE_OPTIMUM}
                  className="default rounded outline"
                >
                  {t("buttons.discover")}
                </Link>
              </div>
            </div>
            <div className={style.presentation}>
              <div className={style.info}>
                <div className={style.subtitle}>
                  <PrismicRichText field={slice.primary.infoTitle} />
                </div>
                <div className={style.description}>
                  <PrismicRichText field={slice.primary.infoSubtitle} />
                </div>
              </div>
              <div className={style.list}>
                <ul>
                  {slice.items.map((item, index) => (
                    <li key={index}>
                      <Text variant="span">{item.title}</Text>
                      <Text variant="p" html={item.description as string} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showVideo && (
        <div className={style.iframe}>
          <ModalVideo
            // @ts-ignore
            url={slice.primary.urlVideo.url}
            close={() => setShowVideo(false)}
          />
        </div>
      )}
    </section>
  );
};

export default HomeAccelerer;
